
<template>
<v-container
      id="subscribe"
      class="px-3 py-12 mb-n9 grey lighten-3"
      fluid
      tag="section"
    >

      <heading>Subscribe to our <span class="primary--text">Newsletter</span></heading>
      <v-row justify="center">
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            hide-details
            label="Email"
            solo
            color="secondary"
            outlined
            single-line
            flat
          >
            <template v-slot:append>
              <v-btn color="primary">
                Subscribe
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
  export default {
    components: {
      Heading: () => import('@/components/Heading'),
    },
  }
</script>